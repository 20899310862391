<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-master-manage',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 220 },
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '1. 대기 배출구', component: () => import(`${'./airMaster01.vue'}`) },
        { name: 'tab2', label: '2. 대기 배출시설', component: () => import(`${'./airMaster02.vue'}`) },
        { name: 'tab3', label: '3. 대기 방지시설', component: () => import(`${'./airMaster03.vue'}`) },
        { name: 'tab5', label: '4. 대기 연료', component: () => import(`${'./airMaster05.vue'}`) },
        { name: 'tab6', label: '5. 대기 원료', component: () => import(`${'./airMaster06.vue'}`) },
        { name: 'tab7', label: '6. 대기 약품', component: () => import(`${'./airMaster07.vue'}`) },
        { name: 'tab4', label: '7. 대기 검사항목 마스터', component: () => import(`${'./airMaster04.vue'}`) },
        { name: 'tab8', label: '8. 사업장별 대기 검사항목', component: () => import(`${'./airMaster08.vue'}`) },
        { name: 'tab9', label: '9. 적산전력계', component: () => import(`${'./airMaster09.vue'}`) },
      ],
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
    },
  }
};
</script>